<template>
    <div class="mt-10">
        <!-- <img :src="form.image" width="200px" height="200px" alt="" /> -->
        <h5 class="md:text-2xl md:mb-10 mb-5 mt-10 text-lg">撥款帳戶</h5>
        <el-form ref="form" :model="form" :rules="rules">
            <div class="grid grid-cols-2 md:gap-4">
                <el-form-item class="w-full md:col-span-1 col-span-2 mb-5" prop="bankCode">
                    <label class="text-gray-400 mb-0 leading-none">
                        銀行代碼
                        <el-input v-model="form.bankCode" class="w-full"></el-input>
                    </label>
                </el-form-item>
                <el-form-item class="w-full md:col-span-1 col-span-2 mb-5" prop="swift">
                    <label class="text-gray-400 mb-0 leading-none">
                        通匯代碼
                        <el-input v-model="form.swift" class="w-full"></el-input>
                    </label>
                </el-form-item>
            </div>
            <div class="grid grid-cols-2 md:gap-4">
                <el-form-item class="w-full md:col-span-1 col-span-2 mb-5" prop="accountName">
                    <label class="text-gray-400 mb-0 leading-none">
                        銀行戶名
                        <el-input v-model="form.accountName" class="w-full"></el-input>
                    </label>
                </el-form-item>
                <el-form-item class="w-full md:col-span-1 col-span-2 mb-5" prop="accountId">
                    <label class="text-gray-400 mb-0 leading-none">
                        銀行帳號
                        <el-input v-model="form.accountId" class="w-full"></el-input>
                    </label>
                </el-form-item>
            </div>
        </el-form>
        <BankPhotoUpload ref="bankPhotoUpload" class="mt-10" :imageURI="imagePassbookURL" @onUploadBankPhotoSuccess="uploadBankPhotoSuccess" />
        <div class="flex justify-center mt-10">
            <btn v-permission="['update']" @onClick="updateBrokerPassbookApi('form')"> 資料更新 </btn>
        </div>
    </div>
</template>

<script>
// 導入自定義 按鈕
import Btn from "@/components/Button.vue";
import BankPhotoUpload from "./Upload.vue";

export default {
    name: "Account",
    components: {
        BankPhotoUpload,
        Btn,
    },
    data() {
        return {
            imagePassbookURL: "",
            form: {
                bankCode: "",
                swift: "",
                accountName: "",
                accountId: "",
                image: "",
            },
            rules: {
                bankCode: [
                    {
                        required: true,
                        message: "銀行代碼為必填",
                        trigger: "blur",
                    },
                ],
                swift: [
                    {
                        required: true,
                        message: "通匯代碼為必填",
                        trigger: "blur",
                    },
                ],
                accountName: [
                    {
                        required: true,
                        message: "帳戶名稱為必填",
                        trigger: "blur",
                    },
                ],
                accountId: [
                    {
                        required: true,
                        message: "銀行帳號為必填",
                        trigger: "blur",
                    },
                ],
            },
        };
    },
    methods: {
        // 更新存摺圖片
        async uploadBankPhotoSuccess(val) {
            this.form.image = val;
        },
        // 取得存摺圖片
        async getBrokerPassbookApi() {
            const data = await this.$api
                .GetBrokerPassbookApi(this.$route.params.id)
                .then((res) => {
                    var reader = new window.FileReader();
                    reader.readAsDataURL(res.data);
                    reader.onload = async () => {
                        var imageDataUrl = reader.result;
                        this.imagePassbookURL = imageDataUrl;
                    };
                })
                .catch(() => {
                    this.imagePassbookURL = "";
                });
            return data;
        },
        //
        getBrokerData() {
            this.$api.GetSingleBrokerApi(this.$route.params.id).then(async (res) => {
                // this.form = { ...res.data };
                if (!this.$isEmpty(res.data.banking)) {
                    // const image = await this.getBrokerPassbookApi();
                    this.form = {
                        bankCode: res.data.banking.bankCode,
                        swift: res.data.banking.swift,
                        accountName: res.data.banking.accountName,
                        accountId: res.data.banking.accountId,
                    };
                    this.imagePassbookURL = await this.getBrokerPassbookApi();
                }
            });
        },
        async updateBrokerPassbookApi() {
            const result = await this.$refs["form"].validate();
            if (result) {
                const { bankCode, swift, accountName, accountId, image } = this.form;
                const result = new FormData();
                result.append("bankCode", bankCode);
                result.append("swift", swift);
                result.append("accountName", accountName);
                result.append("accountId", accountId);
                if (image) {
                    result.append("image", image);
                }
                this.$api
                    .UpdateBrokerPassbookApi(this.$route.params.id, result)
                    .then(() => {
                        this.$message({
                            type: "success",
                            message: "更新成功",
                        });
                        this.getBrokerData();
                    })
                    .catch((err) => {
                        console.log(err);
                        this.$message({
                            type: "warning",
                            message: "更新失敗",
                        });
                    })
                    .finally(() => {
                        this.$emit("update");
                    });
            }
        },
    },
    mounted() {
        this.getBrokerData();
        this.$emit("updateBreadCrumb", "撥款帳戶");
    },
};
</script>
